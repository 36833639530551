import {
  Configuration,
  CurrenciesApiFactory,
  CurrenciesApiInterface,
} from "../openapi";
import {
  AuthApiFactory,
  AuthApiInterface,
  CompaniesApiFactory,
  CompaniesApiInterface,
  EventsApiFactory,
  EventsApiInterface,
  GalleriesApiFactory,
  GalleriesApiInterface,
} from "../openapi/api";
import {
  AdvertisementsApiFactory,
  AdvertisementsApiInterface,
} from "../openapi/api/advertisements-api";
import {
  EventTypesApiFactory,
  EventTypesApiInterface,
} from "../openapi/api/event-types-api";
import { JobsApiFactory, JobsApiInterface } from "../openapi/api/jobs-api";
import { MeApiFactory, MeApiInterface } from "../openapi/api/me-api";
import { MediaApiFactory, MediaApiInterface } from "../openapi/api/media-api";
import {
  NetworksApiFactory,
  NetworksApiInterface,
} from "../openapi/api/networks-api";
import {
  PostsApiFactory,
  PostsApiFp,
  PostsApiInterface,
} from "../openapi/api/posts-api";
import {
  RewardsApiFactory,
  RewardsApiInterface,
} from "../openapi/api/rewards-api";
import { UsersApiFactory, UsersApiInterface } from "../openapi/api/users-api";

export const configuration = new Configuration({});

export function useAuthAPI(): AuthApiInterface {
  return AuthApiFactory(configuration, "");
}

export function useCompaniesAPI(): CompaniesApiInterface {
  return CompaniesApiFactory(configuration, "");
}

export function useEventsAPI(): EventsApiInterface {
  return EventsApiFactory(configuration, "");
}

export function useGalleriesAPI(): GalleriesApiInterface {
  return GalleriesApiFactory(configuration, "");
}

export function useMediaAPI(): MediaApiInterface {
  return MediaApiFactory(configuration, "");
}

export function useNetworksAPI(): NetworksApiInterface {
  return NetworksApiFactory(configuration, "");
}

export function useUsersAPI(): UsersApiInterface {
  return UsersApiFactory(configuration, "");
}

export function useMeAPI(): MeApiInterface {
  return MeApiFactory(configuration, "");
}

export function useCurrenciesApi(): CurrenciesApiInterface {
  return CurrenciesApiFactory(configuration, "");
}

export function useEventTypesApi(): EventTypesApiInterface {
  return EventTypesApiFactory(configuration, "");
}

export function usePostsApi(): PostsApiInterface {
  return PostsApiFactory(configuration, "");
}

export function useAdvertisementsApi(): AdvertisementsApiInterface {
  return AdvertisementsApiFactory(configuration, "");
}

export function useJobOffersApi(): JobsApiInterface {
  return JobsApiFactory(configuration, "");
}

export function useRewardsApi(): RewardsApiInterface {
  return RewardsApiFactory(configuration, "");
}

export const allHooks = { useAuthAPI, useCompaniesAPI, useEventsAPI };

export default allHooks;
