import React from "react";

import { CCol, CContainer, CRow } from "@coreui/react";

import Loader from "./Loader";

interface PageLoaderProperties {
  size?: number;
}

function PageLoader({ size }: PageLoaderProperties): JSX.Element {
  return (
    <div className="c-app c-default-layout sflex-row align-items-center">
      <CContainer fluid>
        <CRow className="justify-content-center">
          <CCol md={4} className="text-center">
            <Loader size={size} />
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

PageLoader.defaultProps = {
  size: 32,
};

export default PageLoader;
