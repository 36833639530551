import React from "react";

import { HashRouter, Route, Switch } from "react-router-dom";

import ListOfRoutes from "../../enums/Route";
import { UseRoute } from "../../hooks/route";
import ApplicationRoute from "../../models/ApplicationRoute";

const DefaultErrorView = React.lazy(
  () => import("../../views/error/Error404View")
);

function ApplicationRouter(): JSX.Element {
  const acceptRoute = (route: ApplicationRoute): boolean => {
    const rootLayout = route.path === "/layout/";
    const inLayout = route.path.startsWith("/layout/");

    return rootLayout || !inLayout;
  };

  return (
    <HashRouter>
      <Switch>
        {Object.values(ListOfRoutes)
          .filter((route) => acceptRoute(route))
          .map((route, index) => UseRoute(route, index))}
        <Route component={DefaultErrorView} />
      </Switch>
    </HashRouter>
  );
}

export default ApplicationRouter;
