import React from "react";

import ReactDOM from "react-dom";

import App from "./App";
// import { logger } from "./hooks/logger";
// import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// reportWebVitals(logger.debug);
