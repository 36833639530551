import React, { useEffect } from "react";

import { Redirect, Route } from "react-router-dom";

import { loadProfile } from "../../contexts/auth/AuthActions";
import ListOfRoutes from "../../enums/Route";
import useAuthState from "../../hooks/auth";
import useLogger from "../../hooks/logger";
import PageLoader from "../loader/PageLoader";

interface ComponentProperties {
  path: string;
  component: React.ComponentType<JSX.Element>;
  exact?: boolean;
}

function PrivateRoute({
  path,
  component,
  exact,
}: ComponentProperties): JSX.Element {
  const { logger } = useLogger();
  const { state, dispatch } = useAuthState();

  useEffect(() => {
    if (!state.initialized && !state.loading) {
      loadProfile(dispatch);
    }
  }, [state, dispatch]);

  if (!state.initialized || state.loading) {
    logger.info("Waiting for app initialization");
    return <PageLoader />;
  }

  if (!state.loggedIn) {
    logger.info("Redirecting to login. This is a private route", path);
    return <Redirect to={ListOfRoutes.AUTH_LOGIN.path} />;
  }

  return <Route path={path} component={component} exact={exact} />;
}

PrivateRoute.defaultProps = {
  exact: false,
};

export default PrivateRoute;
