import React from "react";

import { parse } from "@billjs/query-string";
import { matchPath, useLocation } from "react-router";
import { Route as ReactRoute } from "react-router-dom";

import PrivateRoute from "../components/application-router/PrivateRoute";
import Route from "../enums/Route";
import ApplicationRoute from "../models/ApplicationRoute";
import { CompanyRoleUserListResponse } from "../openapi";

export function matchRoute(path: string, pathname: string): boolean {
  const m = matchPath(pathname, {
    path,
    exact: false,
    strict: false,
  });

  return !!m;
}

export function joinUrl(url: string, data: string[]): string {
  let final: string = url;

  Object.entries(data).forEach((e) => {
    const property = e[0];
    const value = e[1];

    final = final.replace(`/:${property}`, `/${value}`);
    final = final.replace(`$:${property}`, `$${value}`);
  });

  return final;
}

export function useMatchedRoute(): ApplicationRoute {
  const location = useLocation();

  const matched = Object.values(Route).filter((value) => {
    return matchUrl(value.path, location.pathname);
  });

  return matched[0] ?? Route.DASHBOARD;
}

type ApplicationRouteParameters = {
  id?: string;
  name?: string;
};

export function makeRoute(
  route: ApplicationRoute,
  data?: ApplicationRouteParameters
): string {
  let final: string = route.path;

  if (data) {
    Object.keys(data).forEach((name) => {
      const value = data.id;
      final = final.replace(`/:${name}`, `/${value}`);
    });
  }

  return final;
}

export function matchUrl(path: string, pathname: string): boolean {
  const m = matchPath(pathname, {
    path,
    exact: false,
    strict: false,
  });

  return !!m;
}

interface QueryParamterMap {
  [key: string]: string;
}

export function parseUrl(url: string): QueryParamterMap {
  const query: QueryParamterMap = {};

  const pairs = (url[0] === "?" ? url.substr(1) : url).split("&");
  pairs.forEach((_, i) => {
    const pair = pairs[i].split("=");
    const decodedParam = decodeURIComponent(pair[0]);
    const decodedValue = decodeURIComponent(pair[1]);
    query[decodedParam] = decodedValue;
  });

  return query;
}

export function parseUrlParam(url: string, parameter: string): string {
  const paramValue = parseUrl(url)[parameter];
  if (paramValue) {
    return String(paramValue);
  }

  return "";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useQuery(): Record<string, any> {
  const location = useLocation();
  return parse(location.hash.length > 0 ? location.hash : location.search);
}

export function UseRoute(route: ApplicationRoute, index: number): JSX.Element {
  const key = `route-${index}`;
  return route.private ? (
    <PrivateRoute
      key={key}
      path={route.path}
      component={route.view}
      exact={route.exact}
    />
  ) : (
    <ReactRoute
      key={key}
      path={route.path}
      component={route.view}
      exact={route.exact}
    />
  );
}

export function hasAccessToRoute(
  existingRoles: CompanyRoleUserListResponse[],
  requiredRoles: string[]
): boolean {
  if (!requiredRoles || requiredRoles.length === 0) {
    return true;
  }

  // Prefill
  const roleCheck: { [key: string]: boolean } = {};
  requiredRoles?.forEach((requiredRole) => {
    roleCheck[requiredRole] = false;
  });

  // Check
  existingRoles.forEach((role) => {
    if (Object.keys(roleCheck).indexOf(role.role?.key ?? "") !== -1) {
      roleCheck[role.role?.key ?? ""] = true;
    }
  });

  // Final check if all are met
  let hasAccess = true;
  requiredRoles?.forEach((requiredRole) => {
    if (!roleCheck[requiredRole]) {
      hasAccess = false;
    }
  });

  return hasAccess;
}

const allHooks = { useMatchedRoute, useQuery, UseRoute, hasAccessToRoute };

export default allHooks;
