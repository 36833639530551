import React, { useReducer } from "react";

import { AuthStateContext } from "./AuthContext";
import { AuthReducer, defaultAuthState } from "./AuthReducer";

interface ProviderProperties {
  children: React.ReactChildren | React.ReactElement;
}

function AuthProvider({ children }: ProviderProperties): JSX.Element {
  const [state, dispatch] = useReducer(AuthReducer, defaultAuthState);
  return (
    <AuthStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthStateContext.Provider>
  );
}

export default AuthProvider;
