import * as winston from "winston";
import BrowserConsole from "winston-transport-browserconsole";

const level = "debug";
winston.configure({
  transports: [
    new BrowserConsole({
      format: winston.format.simple(),
      level,
    }),
  ],
});

interface LoggerInterface {
  logger: typeof winston;
}

function useLogger(): LoggerInterface {
  return { logger: winston };
}

export const logger = winston;

export default useLogger;
