import React, { lazy } from "react";

import { faBtc } from "@fortawesome/free-brands-svg-icons";
import {
  faAd,
  faCalendarDay,
  faDice,
  faHome,
  faHotel,
  faKey,
  faLandmark,
  faLanguage,
  faMoneyBillWave,
  faNetworkWired,
  faUsers,
  faListUl,
  faBriefcase,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";

import ApplicationRoutes from "../models/ApplicationRoutes";

const Route: ApplicationRoutes = {
  AUTH_LOGIN: {
    path: "/auth/login",
    view: React.lazy(() => import("../views/auth/LoginView")),
    exact: true,
    private: false,
    icon: faHome,
  },
  AUTH_LOGOUT: {
    path: "/auth/logout",
    view: React.lazy(() => import("../views/auth/LoginView")),
    exact: true,
    private: false,
    icon: faHome,
  },
  AUTH_REQUEST_RESET_PASSWORD: {
    path: "/auth/request-reset-password",
    view: React.lazy(() => import("../views/auth/RequestResetPasswordView")),
    exact: true,
    private: false,
    icon: faKey,
  },
  AUTH_RESET_PASSWORD: {
    path: "/auth/reset-password/:token",
    view: React.lazy(() => import("../views/auth/ResetPasswordView")),
    exact: true,
    private: false,
    icon: faKey,
  },
  INVITE_ADMIN: {
    path: "/accept-admin-invite/:token",
    view: React.lazy(
      () => import("../views/my-admins/AcceptAdminInvitationView")
    ),
    exact: true,
    private: false,
    icon: faKey,
  },
  LAYOUT: {
    name: "Layout",
    path: "/layout",
    view: lazy(() => import("../components/layout/Layout")),
    exact: false,
    private: true,
    icon: faKey,
  },
  DASHBOARD: {
    name: "Dashboard",
    path: "/layout/dashboard",
    view: React.lazy(() => import("../views/dashboard/DashboardView")),
    exact: false,
    private: true,
    icon: faHome,
  },
  COMPANIES_LIST: {
    name: "Companies",
    path: "/layout/companies",
    view: React.lazy(() => import("../views/companies/CompaniesListView")),
    exact: true,
    private: true,
    icon: faLandmark,
    requiredRoles: ["rs-system-admin"],
  },
  COMPANIES_ADD: {
    name: "Add Company",
    path: "/layout/companies/add",
    view: React.lazy(() => import("../views/companies/CompaniesAddView")),
    exact: true,
    private: true,
    icon: faLandmark,
  },
  COMPANIES_EDIT: {
    name: "Edit Company",
    path: "/layout/companies/:id",
    view: React.lazy(() => import("../views/companies/CompaniesEditView")),
    exact: true,
    private: true,
    icon: faLandmark,
  },
  NETWORKS_LIST: {
    name: "Networks",
    path: "/layout/networks",
    view: React.lazy(() => import("../views/networks/NetworksListView")),
    exact: true,
    private: true,
    icon: faNetworkWired,
    requiredRoles: ["rs-system-admin"],
  },
  NETWORKS_ADD: {
    name: "Add Network",
    path: "/layout/networks/add",
    view: React.lazy(() => import("../views/networks/NetworksAddView")),
    exact: true,
    private: true,
    icon: faNetworkWired,
    requiredRoles: ["rs-system-admin"],
  },
  NETWORKS_EDIT: {
    name: "Edit Network",
    path: "/layout/networks/:id",
    view: React.lazy(() => import("../views/networks/NetworksEditView")),
    exact: true,
    private: true,
    icon: faNetworkWired,
    requiredRoles: ["rs-system-admin"],
  },
  EVENTS_LIST: {
    name: "Events",
    path: "/layout/events",
    view: React.lazy(() => import("../views/events/EventsListView")),
    exact: true,
    private: true,
    icon: faCalendarDay,
    requiredRoles: ["rs-system-admin"],
  },
  EVENTS_EDIT: {
    name: "Edit Event",
    path: "/layout/events/edit/:id",
    view: React.lazy(() => import("../views/events/EventsEditView")),
    exact: true,
    private: true,
    icon: faCalendarDay,
  },
  EVENTS_ADD: {
    name: "Add Event",
    path: "/layout/events/add",
    view: React.lazy(() => import("../views/events/EventsAddView")),
    exact: true,
    private: true,
    icon: faCalendarDay,
  },
  ADVERTISEMENTS_LIST: {
    name: "Advertisements",
    path: "/layout/advertisements",
    view: React.lazy(
      () => import("../views/advertisements/AdvertisementsListView")
    ),
    exact: true,
    private: true,
    icon: faAd,
    requiredRoles: ["rs-system-admin"],
  },
  ADVERTISEMENTS_ADD: {
    name: "Add Advertisements",
    path: "/layout/advertisements/add",
    view: React.lazy(
      () => import("../views/advertisements/AdvertisementsAddView")
    ),
    exact: true,
    private: true,
    icon: faAd,
    requiredRoles: ["rs-system-admin"],
  },
  ADVERTISEMENTS_EDIT: {
    name: "Edit Advertisements",
    path: "/layout/advertisements/edit/:id",
    view: React.lazy(
      () => import("../views/advertisements/AdvertisementsEditView")
    ),
    exact: true,
    private: true,
    icon: faAd,
    requiredRoles: ["rs-system-admin"],
  },
  JOB_OFFERS_LIST: {
    name: "Job Offers",
    path: "/layout/job-offers",
    view: React.lazy(() => import("../views/job-offers/JobOffersListView")),
    exact: true,
    private: true,
    icon: faBriefcase,
    requiredRoles: ["rs-system-admin"],
  },
  JOB_OFFERS_ADD: {
    name: "Add Job Offers",
    path: "/layout/job-offers/add",
    view: React.lazy(() => import("../views/job-offers/JobOffersAddView")),
    exact: true,
    private: true,
    icon: faBriefcase,
    requiredRoles: ["rs-system-admin"],
  },
  JOB_OFFERS_EDIT: {
    name: "Edit Job Offers",
    path: "/layout/job-offers/edit/:id",
    view: React.lazy(() => import("../views/job-offers/JobOffersEditView")),
    exact: true,
    private: true,
    icon: faBriefcase,
    requiredRoles: ["rs-system-admin"],
  },
  HUB_CODES_LIST: {
    name: "HUB Codes",
    path: "/layout/hub-codes",
    view: React.lazy(() => import("../views/hub-codes/HubCodesListView")),
    exact: true,
    private: true,
    icon: faQrcode,
    requiredRoles: ["rs-system-admin"],
  },
  HUB_CODES_ADD: {
    name: "Add HUB Code",
    path: "/layout/hub-codes/add",
    view: React.lazy(() => import("../views/hub-codes/HubCodesAddView")),
    exact: true,
    private: true,
    icon: faQrcode,
    requiredRoles: ["rs-system-admin"],
  },
  HUB_CODES_EDIT: {
    name: "Edit HUB Code",
    path: "/layout/hub-codes/edit/:id",
    view: React.lazy(() => import("../views/hub-codes/HubCodesEditView")),
    exact: true,
    private: true,
    icon: faQrcode,
    requiredRoles: ["rs-system-admin"],
  },
  // TODO: This should probably be removed or grouped under Enums
  COMPANY_TYPES_LIST: {
    name: "Company Types",
    path: "/layout/company-types",
    view: React.lazy(() => import("../views/events/EventsListView")),
    exact: true,
    private: true,
    icon: faBtc,
    requiredRoles: ["rs-system-admin"],
  },
  CURRENCIES_LIST: {
    name: "Currencies",
    path: "/layout/currencies",
    view: React.lazy(() => import("../views/events/EventsListView")),
    exact: true,
    private: true,
    icon: faBtc,
    requiredRoles: ["rs-system-admin"],
  },
  EQUIPMENT_TYPES_LIST: {
    name: "Equipment Types",
    path: "/layout/equipment-types",
    view: React.lazy(() => import("../views/events/EventsListView")),
    exact: true,
    private: true,
    icon: faHotel,
    requiredRoles: ["rs-system-admin"],
  },
  GAME_TYPES_LIST: {
    name: "Game Types",
    path: "/layout/game-types",
    view: React.lazy(() => import("../views/events/EventsListView")),
    exact: true,
    private: true,
    icon: faDice,
    requiredRoles: ["rs-system-admin"],
  },
  LANGUAGES_LIST: {
    name: "Languages",
    path: "/layout/languages",
    view: React.lazy(() => import("../views/events/EventsListView")),
    exact: true,
    private: true,
    icon: faLanguage,
    requiredRoles: ["rs-system-admin"],
  },
  PAYMENT_TYPES_LIST: {
    name: "Payment Types",
    path: "/layout/payment-types",
    view: React.lazy(() => import("../views/events/EventsListView")),
    exact: true,
    private: true,
    icon: faMoneyBillWave,
    requiredRoles: ["rs-system-admin"],
  },
  USERS_LIST: {
    name: "Users",
    path: "/layout/users",
    view: React.lazy(() => import("../views/users/UsersListView")),
    exact: true,
    private: true,
    icon: faUsers,
    requiredRoles: ["rs-system-admin"],
  },
  USERS_ADD: {
    name: "Add User",
    path: "/layout/users/add",
    view: React.lazy(() => import("../views/users/UsersAddView")),
    exact: true,
    private: true,
    icon: faUsers,
    requiredRoles: ["rs-system-admin"],
  },
  USERS_EDIT: {
    name: "Edit User",
    path: "/layout/users/edit/:id",
    view: React.lazy(() => import("../views/users/UsersEditView")),
    exact: true,
    private: true,
    icon: faUsers,
    requiredRoles: ["rs-system-admin"],
  },
  POSTS_LIST: {
    name: "Posts",
    path: "/layout/posts",
    view: React.lazy(() => import("../views/posts/PostsListView")),
    exact: true,
    private: true,
    icon: faListUl,
    requiredRoles: ["rs-system-admin"],
  },
  POSTS_ADD: {
    name: "Posts",
    path: "/layout/posts/add",
    view: React.lazy(() => import("../views/posts/PostsAddView")),
    exact: true,
    private: true,
    icon: faListUl,
    requiredRoles: ["rs-system-admin"],
  },
  POSTS_EDIT: {
    name: "Posts",
    path: "/layout/posts/edit/:id",
    view: React.lazy(() => import("../views/posts/PostsEditView")),
    exact: true,
    private: true,
    icon: faListUl,
    requiredRoles: ["rs-system-admin"],
  },
  MY_COMPANIES_LIST: {
    name: "My Companies",
    path: "/layout/my/companies",
    view: React.lazy(() => import("../views/my-companies/MyCompaniesListView")),
    exact: true,
    private: true,
    icon: faLandmark,
  },
  MY_EVENTS_LIST: {
    name: "My Events",
    path: "/layout/my/events",
    view: React.lazy(() => import("../views/my-events/MyEventsListView")),
    exact: true,
    private: true,
    icon: faCalendarDay,
  },
  MY_ADMINS_LIST: {
    name: "My Admins",
    path: "/layout/my/admins",
    view: React.lazy(() => import("../views/my-admins/MyAdminsListView")),
    exact: true,
    private: true,
    icon: faUsers,
    requiredRoles: ["rs-owner"],
  },
  MY_PROFILE_EDIT: {
    name: "My Profile",
    path: "/layout/my/user-profile",
    view: React.lazy(
      () => import("../views/my-user-profile/MyUserProfileEditView")
    ),
    exact: true,
    private: true,
    icon: faCalendarDay,
  },
  ERROR_404: {
    path: "/error",
    view: React.lazy(() => import("../views/error/Error404View")),
    exact: false,
    private: false,
    icon: faHome,
  },
  ROOT: {
    path: "/",
    view: React.lazy(() => import("../views/auth/LoginView")),
    exact: true,
    private: true,
    icon: faHome,
  },
};

export default Route;
