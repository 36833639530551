import React from "react";

import { ImpulseSpinner } from "react-spinners-kit";

import Color from "../../enums/Color";

interface LoaderProperties {
  color?: Color;
  size?: number;
}

function Loader({ color, size }: LoaderProperties): JSX.Element {
  return (
    <div
      className="loader text-center"
      style={{ minHeight: "24px", display: "inline-block" }}
    >
      <ImpulseSpinner
        frontColor={color}
        backColor={Color.GRAY_100}
        size={size}
        sizeUnit="px"
      />
    </div>
  );
}

Loader.defaultProps = {
  size: 32,
  color: Color.PRIMARY_75,
};

export default Loader;
