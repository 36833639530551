import { toast } from "react-toastify";

import { configuration } from "../../hooks/api";
import { logger } from "../../hooks/logger";
import {
  AuthApiFactory,
  CompanyRoleUserListResponse,
  LoginRequest,
  TokenResponse,
  UserProfile,
} from "../../openapi";
import { MeApiFactory } from "../../openapi/api/me-api";

export const AUTH_APP_TOKEN = "app.token-0.6.1";

export enum AuthStatusType {
  AUTHENTIFICATED = "AUTHENTIFICATED",
  UNAUTHENTIFICATED = "UNAUTHENTIFICATED",
}

export enum AuthActionType {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",

  PROFILE_REQUEST = "PROFILE_REQUEST",
  PROFILE_SUCCESS = "PROFILE_SUCCESS",
  PROFILE_ERROR = "PROFILE_ERROR",

  LOGOUT = "LOGOUT",
}

type AuthActionPayload = {
  token?: TokenResponse;
  user?: UserProfile;
  roles?: CompanyRoleUserListResponse[];
  isSystemAdmin?: boolean;
};

export interface AuthAction {
  type: AuthActionType;
  payload?: AuthActionPayload;
  error?: string;
}

export async function loginUser(
  dispatch: (action: AuthAction) => void,
  loginPayload: LoginRequest
): Promise<boolean> {
  const authAPI = AuthApiFactory(undefined, "");

  try {
    dispatch({ type: AuthActionType.LOGIN_REQUEST });
    const response = await authAPI.doLogin(loginPayload);

    if (response.data.token) {
      dispatch({
        type: AuthActionType.LOGIN_SUCCESS,
        payload: { token: response.data },
      });
      localStorage.setItem(AUTH_APP_TOKEN, response.data.token);
      toast.success("login was successfull :)");
      return true;
    }

    dispatch({ type: AuthActionType.LOGIN_ERROR, error: "login error" });
  } catch (error) {
    dispatch({ type: AuthActionType.LOGIN_ERROR, error });
  }

  return false;
}

export async function loadProfile(
  dispatch: (action: AuthAction) => void
): Promise<void> {
  logger.debug("LOADING PROFILE INFO");

  const token = localStorage.getItem(AUTH_APP_TOKEN) ?? "";
  if (token.length === 0) {
    logger.error("Bad token");
    dispatch({
      type: AuthActionType.PROFILE_ERROR,
      error: "Wrong token",
    });
    return Promise.resolve();
  }

  configuration.apiKey = `Bearer ${token}`;
  const meAPI = MeApiFactory(configuration, "");

  try {
    dispatch({ type: AuthActionType.PROFILE_REQUEST });
    const response = await meAPI.getMyProfile("en", {
      params: {
        timestamp: new Date().getTime(),
      },
    });

    if (response.data) {
      const roles = await meAPI.getMyRoles({
        params: {
          timestamp: new Date().getTime(),
        },
      });
      if (roles.data) {
        let isSystemAdmin = false;
        roles.data.forEach((role) => {
          if (role.role?.key === "rs-system-admin") {
            isSystemAdmin = true;
          }
        });

        dispatch({
          type: AuthActionType.PROFILE_SUCCESS,
          payload: {
            user: response.data,
            roles: roles.data,
            isSystemAdmin,
          },
        });
        // changeLanguage(response.data.language ?? Language.DEFAULT);
        return Promise.resolve();
      }
    }

    dispatch({
      type: AuthActionType.PROFILE_ERROR,
      error: "Something is wrong",
    });
  } catch (error) {
    dispatch({ type: AuthActionType.PROFILE_ERROR, error });
  }

  return Promise.resolve();
}

export async function logout(
  dispatch: (action: AuthAction) => void
): Promise<void> {
  dispatch({ type: AuthActionType.LOGOUT });
  localStorage.removeItem("app.user");
  localStorage.removeItem(AUTH_APP_TOKEN);

  return Promise.resolve();
}
