import React, { Dispatch } from "react";

import { AuthAction } from "../contexts/auth/AuthActions";
import { AuthStateContext } from "../contexts/auth/AuthContext";
import { AuthState } from "../contexts/auth/AuthReducer";

function useAuthState(): { state: AuthState; dispatch: Dispatch<AuthAction> } {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

export default useAuthState;
