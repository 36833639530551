import React, { Suspense, useEffect } from "react";

import { toast } from "react-toastify";

import ApplicationRouter from "./components/application-router/ApplicationRouter";
import PageLoader from "./components/loader/PageLoader";
import AuthProvider from "./contexts/auth/AuthProvider";
import useAuthState from "./hooks/auth";
import useLogger from "./hooks/logger";
import setupI18n from "./i18n";

import "./App.scss";

// todo: Move this global configuration for Toast
toast.configure({
  hideProgressBar: true,
  position: "top-center",
  draggable: false,
  className: "custom-toast-container",
  autoClose: 5000,
  closeButton: (
    <>
      <i className="icon-close" />
    </>
  ),
});

function App(): JSX.Element {
  const { logger } = useLogger();
  const { state } = useAuthState();

  useEffect(() => {
    setupI18n((language) => {
      logger.debug("Current language:", language);
    });
  }, [logger, state]);

  return (
    <AuthProvider>
      <Suspense fallback={<PageLoader />}>
        <ApplicationRouter />
      </Suspense>
    </AuthProvider>
  );
}

export default App;
