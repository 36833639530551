import { logger } from "../../hooks/logger";
import { CompanyRoleUserListResponse, UserProfile } from "../../openapi";
import { AuthAction, AuthActionType, AUTH_APP_TOKEN } from "./AuthActions";

const user = localStorage.getItem("app.user")
  ? JSON.parse(localStorage.getItem("app.user") ?? "")
  : {};
const token = localStorage.getItem(AUTH_APP_TOKEN) ?? "";

export interface AuthState {
  user: UserProfile;
  roles: CompanyRoleUserListResponse[];
  isSystemAdmin: boolean;
  loggedIn: boolean;
  loading: boolean;
  initialized: boolean;
  token: string;
  errorMessage: string;
}

export const defaultAuthState: AuthState = {
  user: user || {},
  roles: [],
  loggedIn: false,
  loading: false,
  isSystemAdmin: false,
  initialized: false,
  token: token || "",
  errorMessage: "",
};

export const AuthReducer = (
  initialState: AuthState,
  action: AuthAction
): AuthState => {
  logger.debug("AUTH Action: ", action);
  switch (action.type) {
    case AuthActionType.LOGIN_REQUEST:
      return {
        ...initialState,
        loading: true,
      };

    case AuthActionType.LOGIN_SUCCESS:
      return {
        ...initialState,
        token: action.payload?.token?.token ?? "",
        loading: false,
        loggedIn: true,
      };

    case AuthActionType.LOGIN_ERROR:
      return {
        ...initialState,
        loading: false,
        loggedIn: false,
        errorMessage: action?.error || "",
      };

    case AuthActionType.PROFILE_REQUEST:
      return {
        ...initialState,
        loading: true,
        initialized: false,
      };

    case AuthActionType.PROFILE_SUCCESS:
      return {
        ...initialState,
        user: action.payload?.user ?? {},
        roles: action.payload?.roles ?? [],
        isSystemAdmin: action.payload?.isSystemAdmin ?? false,
        loading: false,
        loggedIn: true,
        initialized: true,
      };

    case AuthActionType.PROFILE_ERROR:
      return {
        ...initialState,
        loading: false,
        loggedIn: false,
        initialized: true,
        token: "",
        errorMessage: action?.error || "",
      };

    case AuthActionType.LOGOUT:
      return {
        ...initialState,
        user: {},
        token: "",
        loggedIn: false,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
